.glow_on_hover {
  width: 100%;
  /* height: 50px; */
  border: none;
  outline: none;
  color: #fff;
  background: #018081;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  padding: 10px 0;
}

.glow_on_hover:before {
  content: "";
  background-color: white;
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.glow_on_hover:active {
  color: #018081;
}

.glow_on_hover:active:after {
  background: transparent;
}

.glow_on_hover:hover:before {
  opacity: 1;
}

.glow_on_hover:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #018081;
  left: 0;
  top: 0;
  border-radius: 10px;
}
